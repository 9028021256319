import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

import styles from './404.module.css'

const C404 = () => {	
	return (
		<>
		<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.itemLeft}>
						<h2>404</h2>
						<h5>Page not found</h5>
						<p>The page you are looking for might have been removed had it’s name changed or is temporaily unavailable.</p>
						<Link to="/">HOMEPAGE</Link>
					</div>
				</div>
			</div>
		</>
	)
}

export default C404