import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import C404 from "../components/404"
import DetailsHead from "../components/detailshead"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <DetailsHead title="404"/>
    <C404/>
  </Layout>
)

export default NotFoundPage
